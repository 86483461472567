import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    seniorLeadershipTeam: Core.ImageAttributes;
    populismPakistan: Core.ImageAttributes;
    totteringGlobalFinance: Core.ImageAttributes;
    strategyAndStatecraft: Core.ImageAttributes;
    johannesUrpelainen: Core.ImageParagraphWrapImageProps;
    monicaDeBolle: Core.ImageParagraphWrapImageProps;
    deveshKapur: Core.ImageParagraphWrapImageProps;
    andrewMertha: Core.ImageParagraphWrapImageProps;
    eugeneFinkel: Core.ImageParagraphWrapImageProps;
    jonasNahm: Core.ImageParagraphWrapImageProps;
    pavithraSuryanarayan: Core.ImageParagraphWrapImageProps;
    matthiaMatthijs: Core.ImageParagraphWrapImageProps;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    seniorLeadershipTeam,
    populismPakistan,
    totteringGlobalFinance,
    strategyAndStatecraft,
    johannesUrpelainen,
    monicaDeBolle,
    deveshKapur,
    andrewMertha,
    eugeneFinkel,
    jonasNahm,
    pavithraSuryanarayan,
    matthiaMatthijs
  } = props.data;

  return (
    <Layout departmentSlug="dispatch" title="Dispatch">
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.DepartmentLabel>Dispatch</Core.DepartmentLabel>
          <Core.Flag>SAIS News</Core.Flag>
        </Core.ContentSection>
        <Core.ContentSection id="senior-leadership-team-restructured">
          <Core.PrimaryHeading>
            Senior Leadership Team Restructured
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>At the beginning</Core.LeadIn> of this academic year,
            Dean Vali Nasr announced changes to SAIS’ senior leadership team.
          </p>
          <p>
            <Core.BoldText>Peter Lewis</Core.BoldText>, who led the Office of
            Academic and Faculty Affairs as vice dean since the office was
            restructured in 2015, overseeing significant changes to the school’s
            academic life and faculty, has stepped down from the administrative
            position and returned to his role as director of Africa and Middle
            East programs.
          </p>
          <p>
            “As we continue our focus on faculty growth and curriculum
            development, we have witnessed the responsibilities of the office of
            vice dean for academic and faculty affairs expand considerably,”
            Nasr notes. “In order to accommodate these demands and to better
            align the management of academic and faculty affairs with standard
            practices across the university, the responsibilities of the Office
            of Academic and Faculty Affairs will be divided among an executive
            vice dean and vice dean.”
          </p>
          <Core.FullWidthImage
            image={seniorLeadershipTeam}
            alt="Eliot Cohen and Kent Calder"
            caption={
              <>
                <Core.LeadIn>Left:</Core.LeadIn> Eliot Cohen,{' '}
                <Core.LeadIn>Right:</Core.LeadIn> Kent Calder
              </>
            }
          />
          <p>
            <Core.BoldText>Eliot Cohen</Core.BoldText> serves as executive vice
            dean. In this position, he is responsible for the academic
            curriculum and will have additional authority to oversee and manage
            academic budget and operations, program development, and
            restructuring efforts. He will also help shape and advance SAIS’
            academic priorities. <Core.BoldText>Kent Calder</Core.BoldText> now
            serves as vice dean for faculty affairs and international research
            cooperation, overseeing the critical ongoing development of faculty,
            the tenure-track process, mentorship of junior faculty, and visiting
            scholar appointments.
          </p>
          <p>
            As SAIS celebrates its 75th anniversary, the school is undertaking a
            transformational shift in its curriculum and structure to meet the
            needs of students and reinforce the school’s role in thought
            leadership, Nasr says, adding, “Together, Professors Calder and
            Cohen will ensure the school strengthens its intellectual community
            in conjunction with the important developments currently underway.”
          </p>
          <Core.Share id="senior-leadership-team-restructured" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="four-faculty-members-assume-leadership-roles">
          <Core.PrimaryHeading>
            Four Faculty Members Assume Leadership Roles
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>SAIS has welcomed</Core.LeadIn> four faculty to
            important leadership roles:
          </p>
          <Core.ImageParagraphWrap image={johannesUrpelainen}>
            <Core.BoldText>Johannes Urpelainen</Core.BoldText> is the director
            of the Energy, Resources and Environment Program. He also serves as
            the Prince Sultan bin Abdulaziz Professor of Energy, Resources and
            Environment, and founding director of the Initiative for Sustainable
            Energy Policy. Urpelainen’s research focuses on sustainable
            solutions to the problem of lacking energy access in emerging
            economies. He earned his PhD in political science from the
            University of Michigan.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={monicaDeBolle}>
            <Core.BoldText>Monica de Bolle</Core.BoldText> is the Riordan Roett
            Chair and director of the Latin American Studies Program and
            director of the Emerging Markets Specialization. Named as Honored
            Economist in 2014 by the Order of Brazilian Economists, de Bolle
            focuses her research on macroeconomics, foreign exchange policy,
            monetary and fiscal policy, trade and inequality, financial
            regulation, and capital markets. She received her PhD in economics
            from the London School of Economics and Political Science.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={deveshKapur}>
            <Core.BoldText>Devesh Kapur</Core.BoldText> is director of Asia
            programs and the Starr Foundation Chair in South Asia Studies. His
            research interests include international financial institutions,
            political and economic consequences of migration, and the effects of
            market forces and urbanization on the well-being of socially
            marginalized groups in India. He is the author or co-author of
            numerous books, including{' '}
            <em>The Other One Percent: Indians in America</em>. Kapur received
            his PhD in public policy from Princeton University.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={andrewMertha}>
            <Core.BoldText>Andrew Mertha</Core.BoldText> is director of the
            China Studies Program, director of SAIS China, and the George and
            Sadie Hyman Professor of China Studies. He specializes in Chinese
            bureaucratic politics, political institutions, and the domestic and
            foreign policy process (see &ldquo;
            <a href="/feature/on-the-shoulders-of-giants">
              On the Shoulders of Giants
            </a>
            &rdquo;). Mertha is on the editorial committees for the{' '}
            <em>Journal of Comparative Politics, The China Quarterly</em>, and{' '}
            <em>Asian Survey</em>. He received his PhD in political science from
            the University of Michigan.
          </Core.ImageParagraphWrap>
          <Core.Share id="four-faculty-members-assume-leadership-roles" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="faculty-honored-at-aspa-conference">
          <Core.PrimaryHeading>
            Faculty Honored at APSA Conference
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Four SAIS faculty members</Core.LeadIn> were honored
            with awards at the 2018 annual conference of the American Political
            Science Association, which was held in Boston late last summer:
          </p>
          <Core.ImageParagraphWrap image={eugeneFinkel}>
            <Core.BoldText>Eugene Finkel</Core.BoldText>, associate professor of
            Conflict Management and European and Eurasian Studies, received an
            honorable mention in the Luebbert Best Article Award category in the
            field of comparative politics. His article “The Death Camp Eldorado:
            Political and Economic Effects of Mass Violence,” co-authored with
            Volha Charnysh, assistant professor at MIT, explores the local-level
            political and economic effects of the transfer and redistribution of
            wealth accompanied by violent conflicts throughout the world.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={jonasNahm}>
            <Core.BoldText>Jonas Nahm</Core.BoldText>, assistant professor of
            Energy, Resources and Environment, received the 2018 Evan Ringquist
            Award, given by the Science, Technology & Environmental Politics
            section of the APSA for the best paper published in the last two
            years. “The Power of Process: State Capacity and Climate Policy,”
            co-authored with Jonas Meckling, assistant professor of energy and
            environmental policy at the University of California, Berkeley,
            examines strategies to overcome opposition from vested interest in
            climate policymaking.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={pavithraSuryanarayan}>
            <Core.BoldText>Pavithra Suryanarayan</Core.BoldText>, assistant
            professor of International Political Economy, received the 2018
            Mancur Olson Award presented by the Political Economy section of the
            APSA for the best dissertation in political economy completed in the
            previous two years. Her dissertation, “Hollowing Out the State:
            Essays on Status Inequality, Fiscal Capacity, and Right-Wing Voting
            in India,” argues that elites anticipating greater public spending
            after franchise expansion seek to weaken taxation. If the state
            cannot tax, it cannot provide public goods effectively. Using
            colonial India as the illustrative case, she shows that an expansion
            of voting rights to Indians was followed by a period of declining
            tax institutions.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={matthiaMatthijs}>
            <Core.BoldText>Matthias Matthijs</Core.BoldText>, assistant
            professor of International Political Economy, earned the 2018 Best
            Paper Award of the European Politics and Society section of the
            APSA. His paper, “When Is It Rational to Learn the Wrong Lessons?
            Technocratic Authority, Social Learning and Euro Fragility,”
            co-authored with Mark Blyth, professor of political economy at Brown
            University, traces the development of the euro’s governing ideas
            over fiscal and monetary policy in the face of mounting evidence
            that continued adherence to those ideas was economically
            deleterious.
          </Core.ImageParagraphWrap>
          <Core.Share id="faculty-honored-at-aspa-conference" />
        </Core.ContentSection>

        <Core.HorizontalRule />
        <Core.Flag>SAIS in the News</Core.Flag>
        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={populismPakistan}
              alt="hands tied illustration"
              contentSectionPlacement="marginalia"
            />
          }
          id="populism-or-status-quo-in-pakistan"
        >
          <Core.PrimaryHeading>
            Populism or Status Quo in Pakistan?
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Strong turnout</Core.LeadIn> overshadowed signs of
            strife and possible election irregularities as voters in Pakistan
            elected former cricket star Imran Khan the next prime minister last
            summer. Experts debated the implications of Khan’s victory for
            Pakistan’s political future and foreign policy in the region.
          </p>
          <p>
            Adjunct Assistant Professor of Global Policy{' '}
            <Core.BoldText>Madiha Afzal</Core.BoldText> wrote for Brookings
            (July 27, 2018) that the outcome was driven by dissatisfaction with
            mainstream politicians, as the people chose “a populist who says
            that he will deliver social services—education and health—for all.”
            Others questioned the legitimacy of the outcome, citing widespread
            voter intimidation. Associate Professor of the Practice of South
            Asia Studies <Core.BoldText>Joshua White</Core.BoldText> said in{' '}
            <em>The Wall Street Journal</em> (July 12, 2018) that he sees the
            weaponization of the judiciary and the manipulation of the media as
            evidence that the military cleared the way for Khan’s ascendance.
          </p>
          <p>
            Drawing comparisons to U.S. President Donald Trump, observers asked
            if Khan’s outsider status will make him an effective agent of
            change. Academic Director of Global Policy{' '}
            <Core.BoldText>Daniel Markey</Core.BoldText> said on BBC (July 26,
            2018), “He doesn’t really have any governing experience, and his
            political party has been cobbled together from a lot of people who
            have been, frankly, corrupt in the past.”
          </p>
          <p>
            As for Pakistan’s ties with the U.S., the relationship hinges on
            Pakistan’s support for the war in Afghanistan. Foreign Policy
            Institute Fellow <Core.BoldText>Shamila Chaudhary</Core.BoldText>{' '}
            told <em>The New York Times</em> (July 31, 2018), “Khan and the
            Pakistani military will want Pakistan to have a very strong role in
            shaping Afghanistan’s future. I don’t think the U.S. is angling for
            Pakistan to have a strong role.”
          </p>
          <Core.Share id="populism-or-status-quo-in-pakistan" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="fanning-anit-americanism-in-canada">
          <Core.PrimaryHeading>
            Fanning Anti-Americanism in Canada
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>As an updated</Core.LeadIn> NAFTA pact moved forward in
            early fall, SAIS Center for Canadian Studies Director{' '}
            <Core.BoldText>Christopher Sands</Core.BoldText> wrote in OpenCanada
            (Oct. 3, 2018) about the serious damage the negotiating process has
            caused. Now that the Trump administration has demanded Canada accept
            less and do more, “anti-Americanism will have a wider appeal” among
            Canadians, he predicted.
          </p>
          <Core.Share id="fanning-anit-americanism-in-canada" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="tottering-global-finance"
          marginalia={
            <Core.FullWidthImage
              image={totteringGlobalFinance}
              alt="people on a scale illustration"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <Core.PrimaryHeading>Global Trade in Jeopardy</Core.PrimaryHeading>
          <p>
            <Core.LeadIn>In September</Core.LeadIn>, Senior Research Professor
            of International Economics{' '}
            <Core.BoldText>Anne O. Krueger</Core.BoldText> wrote in{' '}
            <em>Asia Times</em> (Sept. 19, 2018) about her concerns that the
            global financial system is in jeopardy. Pointing out that Washington
            is blocking all nominees to replace expiring terms of World Trade
            Organization arbitration judges, she noted, “Once there is no
            quorum, no appeals cases can be heard, and some countries might
            start to violate WTO rules with impunity.”
          </p>
          <Core.Share id="tottering-global-finance" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="democracy-tested-in-brasil">
          <Core.PrimaryHeading>Democracy Tested in Brazil</Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Brazil’s democracy</Core.LeadIn> “faces a stern test,”
            with newly elected President Jair Bolsonaro at the helm, wrote SAIS
            Associate Professor of International Economics{' '}
            <Core.BoldText>Filipe Campante</Core.BoldText> in an opinion piece
            that appeared in the Oct. 29, 2018, issue of Bloomberg. “The most
            important question is: Can Brazilian institutions withstand the
            threat posed by the man’s well-documented authoritarian and
            illiberal streaks?” observed Campante. While some Brazilians hold
            out hope for Bolsonaro to moderate, Campante is less optimistic.
          </p>
          <p>
            “His ascent to power was only made possible by extreme polarization
            and disenchantment with the political establishment. His incentive
            will be to fan the flames that ignited his candidacy in the first
            place—not unlike his idol President Donald Trump, whose ‘pivot to
            the center’ keeps not coming. Brazilian democracy may not collapse.
            But the odds are strong that it will experience a 21st-century-style
            democratic backsliding, with all the accompanying erosion of norms
            and guarantees that sad progression entails.”
          </p>
          <p>
            <Core.BoldText>Monica de Bolle</Core.BoldText>, the Riordan Roett
            Chair in Latin American Studies and director of the Emerging Markets
            Specialization at SAIS, also weighed in on Bolsonaro’s election.
            Writing in <em>The New York Times</em> opinion section (Oct. 11,
            2018), she noted that although austerity could hurt Brazil’s growth
            in the short term, “the only way to reduce the widening gap between
            revenues and expenditures is by paying the price of a weaker economy
            now to guarantee the return of stability and growth later.”
          </p>
          <Core.Share id="democracy-tested-in-brasil" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="strategy-and-statecraft-for-undergraduates">
          <Core.Flag>Sphere of Influence</Core.Flag>
          <Core.PrimaryHeading>
            Strategy and Statecraft for Undergraduates
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Building upon the success</Core.LeadIn> of the
            Kissinger seminar offered to graduate students at SAIS, the Henry A.
            Kissinger Center for Global Affairs, with the support of the JHU
            Provost’s office, has crafted a special course to be offered to
            select Johns Hopkins University undergraduates beginning this spring
            semester.
          </p>
          <p>
            Like the graduate seminar in Washington, the class will be rooted in
            historically informed strategy, statecraft, and geopolitics.
            Interdisciplinary in design, the seminar will draw upon a number of
            fields, including political science, law, ethics, economics, and
            history, to provide students with a sharp conceptual and empirical
            lens to understand and prepare for a rapidly changing world.
          </p>
          <p>
            The course will examine foundational texts, from Thucydides’
            <em>History of the Peloponnesian War</em> to Machiavelli’s{' '}
            <em>The Prince</em>. It will explore pivotal moments and individuals
            in the history of world politics, in addition to the making of the
            United States-led world order. Throughout the semester, the course
            will focus on connecting scholarship and decision-making on both
            history and theory to better address the most vexing issues
            policymakers face today.
          </p>
        </Core.ContentSection>
        <Core.FullWidthImage
          image={strategyAndStatecraft}
          alt="ancient war etching"
        />
        <Core.ContentSection>
          <p>
            Classes will meet on both the Homewood and SAIS campuses, and
            feature a mix of curricular and co-curricular activities, with an
            eye toward grappling with the most important grand strategy
            questions of the day. Students will have the opportunity to engage
            with leading policymakers in a small group setting, as well as
            participate in group and interactive activities.
          </p>
          <p>
            The Kissinger center hopes this is the beginning of deeper and more
            extensive interactions with the undergraduate population at
            Homewood.
          </p>
          <Core.SignOff>
            Francis J. Gavin, the Giovanni Agnelli Distinguished Professor and
            Director of the Henry A. Kissinger Center for Global Affairs
          </Core.SignOff>
          <Core.Share id="strategy-and-statecraft-for-undergraduates" />
        </Core.ContentSection>

        <Core.MoreResources>
          To stay up to date on SAIS experts in the news, visit{' '}
          <a
            href="http://www.sais-jhu.edu/opt-in"
            target="_blank"
            rel="noopener noreferrer"
          >
            sais-jhu.edu/opt-in
          </a>{' '}
          and select the option for <strong>The Brief</strong>, which is
          produced monthly by the Office of Marketing, Communications, and
          Strategic Initiatives.
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    seniorLeadershipTeam: file(
      relativePath: { regex: $directory }
      name: { eq: "senior-leadership-team" }
    ) {
      ...ContentWidthImage
    }
    populismPakistan: file(
      relativePath: { regex: $directory }
      name: { eq: "populism-pakistan" }
    ) {
      ...MarginaliaImage
    }
    totteringGlobalFinance: file(
      relativePath: { regex: $directory }
      name: { eq: "tottering-global-finance" }
    ) {
      ...MarginaliaImage
    }
    strategyAndStatecraft: file(
      relativePath: { regex: $directory }
      name: { eq: "strategy-and-statecraft" }
    ) {
      ...FullWidthImage
    }
    johannesUrpelainen: file(
      relativePath: { regex: $directory }
      name: { eq: "johannes-urpelainen" }
    ) {
      ...ImagePragraphWrapImage
    }
    monicaDeBolle: file(
      relativePath: { regex: $directory }
      name: { eq: "monica-de-bolle" }
    ) {
      ...ImagePragraphWrapImage
    }
    deveshKapur: file(
      relativePath: { regex: $directory }
      name: { eq: "devesh-kapur" }
    ) {
      ...ImagePragraphWrapImage
    }
    andrewMertha: file(
      relativePath: { regex: $directory }
      name: { eq: "andrew-mertha" }
    ) {
      ...ImagePragraphWrapImage
    }
    eugeneFinkel: file(
      relativePath: { regex: $directory }
      name: { eq: "eugene-finkel" }
    ) {
      ...ImagePragraphWrapImage
    }
    jonasNahm: file(
      relativePath: { regex: $directory }
      name: { eq: "jonas-nahm" }
    ) {
      ...ImagePragraphWrapImage
    }
    pavithraSuryanarayan: file(
      relativePath: { regex: $directory }
      name: { eq: "pavithra-suryanarayan" }
    ) {
      ...ImagePragraphWrapImage
    }
    matthiaMatthijs: file(
      relativePath: { regex: $directory }
      name: { eq: "matthias-matthijs" }
    ) {
      ...ImagePragraphWrapImage
    }
  }
`;

export default DepartmentContent;
